@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base */

@import './css/default/base.css';
@import './css/default/variables.css';
@import './css/default/animations.css';

/* Components */

@import './css/components/shell.css';
@import './css/components/header.css';
@import './css/components/footer.css';
@import './css/components/logo.css';
@import './css/components/tab.css';
@import './css/components/socials.css';
@import './css/components/icon.css';
@import './css/components/icon-circle.css';
@import './css/components/nav.css';
@import './css/components/nav-trigger.css';
@import './css/components/hero.css';
@import './css/components/btn.css';
@import './css/components/logo-slider.css';
@import './css/components/products.css';
@import './css/components/product.css';
@import './css/components/cta.css';
@import './css/components/form-cta.css';
@import './css/components/sidebar.css';
@import './css/components/sidebar-item.css';
@import './css/components/cart-products.css';
@import './css/components/dialog.css';
@import './css/components/search.css';
@import './css/components/price-range.css';
@import './css/components/brands-select.css';

h3{
    font-size: 25px!important;
}

@layer base {
  :root {
        --background: 0 0% 100%;
        --foreground: 0 0% 3.9%;
        --card: 0 0% 100%;
        --card-foreground: 0 0% 3.9%;
        --popover: 0 0% 100%;
        --popover-foreground: 0 0% 3.9%;
        --primary: 0 0% 9%;
        --primary-foreground: 0 0% 98%;
        --secondary: 0 0% 96.1%;
        --secondary-foreground: 0 0% 9%;
        --muted: 0 0% 96.1%;
        --muted-foreground: 0 0% 45.1%;
        --accent: 0 0% 96.1%;
        --accent-foreground: 0 0% 9%;
        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 0 0% 98%;
        --border: 0 0% 89.8%;
        --input: 0 0% 89.8%;
        --ring: 0 0% 3.9%;
        --chart-1: 12 76% 61%;
        --chart-2: 173 58% 39%;
        --chart-3: 197 37% 24%;
        --chart-4: 43 74% 66%;
        --chart-5: 27 87% 67%;
        --radius: 0.5rem;
    }
  .dark {
        --background: 0 0% 3.9%;
        --foreground: 0 0% 98%;
        --card: 0 0% 3.9%;
        --card-foreground: 0 0% 98%;
        --popover: 0 0% 3.9%;
        --popover-foreground: 0 0% 98%;
        --primary: 0 0% 98%;
        --primary-foreground: 0 0% 9%;
        --secondary: 0 0% 14.9%;
        --secondary-foreground: 0 0% 98%;
        --muted: 0 0% 14.9%;
        --muted-foreground: 0 0% 63.9%;
        --accent: 0 0% 14.9%;
        --accent-foreground: 0 0% 98%;
        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 0 0% 98%;
        --border: 0 0% 14.9%;
        --input: 0 0% 14.9%;
        --ring: 0 0% 83.1%;
        --chart-1: 220 70% 50%;
        --chart-2: 160 60% 45%;
        --chart-3: 30 80% 55%;
        --chart-4: 280 65% 60%;
        --chart-5: 340 75% 55%;
    }
}

@layer base {
  * {
    @apply border-border;
    }
  body {
    @apply bg-background text-foreground;
    }
}


