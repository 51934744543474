.header {
    .header__container {
        padding: 30px 0;

        @media only screen and (max-width: 1024px) {
            padding: 20px 0;
        }
    }

    .header__inner {
        display: grid;
        align-items: center;

        @media only screen and (max-width: 1024px) {
            grid-template-columns: 1fr 1fr;
        }
    }

    .header__icons {
        display: flex;
        align-items: center;
        justify-content: end;
    }

    .header__icons {
        display: flex;
        flex-flow: row;
        align-items: end;

        @media only screen and (max-width: 1024px) {
            display: none;
        }
    }
}