.btn {
    font-size: 16px;
    line-height: 1 !important;
    font-weight: 600;
    display: inline-block;
    padding: 16px 32px;
    border: none;
    border-radius: 99em;
    background-color: var(--color-orange);
    color: var(--color-white);
    cursor: pointer;
    transition-property: color, background-color;
    transition-duration: var(--transition-duration);

    &:hover {
        background-color: var(--color-black);
    }
    @media (max-width: 430px) {
        font-size: 10px;
        padding: 12px 10px;
       
    }
}

.btn--outlined {
    padding: 12px 26px;
    border: 1px solid var(--color-black);
    background-color: transparent;
    color: var(--color-black);
    text-shadow: unset;

    &:hover {
        color: var(--color-white);
    }
}

.btn--full {
    width: 100%;
    text-align: center;
}