@keyframes rotateHamburger {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(180deg);
    }
}

@keyframes moveItems {
    0% {
        transform: translateY(-20px);
        opacity: 0;
    }

    30% {
        transform: translateY(-15px);
        opacity: .2;
    }

    50% {
        transform: translateY(-10px);
        opacity: .3;
    }

    80% {
        transform: translateY(-5px);
        opacity: .4;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes showClickGuide {
    0% {
        opacity: 0;
        transform: scale(.5);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}