.nav {
    padding-left: 30px;

    ul {
        display: flex;
        gap: 20px;
        align-items: center;
    }

    a {
        position: relative;
        font-size: 20px;
        font-weight: 600;
        color: var(--color-black);

        &:after {
            position: absolute;
            left: 0;
            bottom: -5px;
            content: '';
            display: block;
            width: 0;
            height: 2px;
            border-radius: 99em;
            background-color: var(--color-black);
            transition: width var(--transition-duration);
        }

        &:hover {
            &:after {
                width: 100%;
            }
        }
    }

    .nav-item-mobile {
        display: none;

        @media only screen and (max-width: 1024px) {
            display: block;
        }
    }

    @media only screen and (max-width: 1024px) {
        padding: 0;

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all var(--transition-duration);
        transform: scale(0);
        border-radius: 50%;
        z-index: 1;

        &.visible {
            transform: scale(1);
            background: #393939;
            border-radius: 0;
        }

        &.active ul {
            li:nth-child(1) {
                animation: moveItems 1s linear 0s forwards;
            }

            li:nth-child(2) {
                animation: moveItems 1s linear .5s forwards;
            }

            li:nth-child(3) {
                animation: moveItems 1s linear 1s forwards;
            }
        }

        ul {
            display: unset;
            align-items: unset;
            gap: unset;

            position: absolute;
            max-width: 400px;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
        }

        li {
            cursor: pointer;
            transition: all .3s;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            font-weight: unset;

            &:after {
                display: none;
            }

            font-size: 24px;
            color: var(--color-white);
        }
    }
}

.nav--mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all .3s;
    transform: scale(0);
    border-radius: 50%;
    z-index: 1;

    &.visible {
        transform: scale(1);
        background: #393939;
        border-radius: 0;
    }

    &.active ul {
        li:nth-child(1) {
            animation: moveItems 1s linear 0s forwards;
        }

        li:nth-child(2) {
            animation: moveItems 1s linear .5s forwards;
        }

        li:nth-child(3) {
            animation: moveItems 1s linear 1s forwards;
        }
    }

    ul {
        position: absolute;
        max-width: 400px;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    li {
        opacity: 0;
        cursor: pointer;
        transition: all .3s;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    a {
        font-size: 24px;
        color: var(--color-white);
    }
}