.hero {
    position: relative;
    min-height: 640px;
    padding-block: 60px;

    @media (max-width: 1024px) {
        text-align: center;
        min-height: fit-content;
    }

    @media (max-width: 425px) {
        text-align: center;
        min-height: fit-content;
        padding-block: 20px;
    }

    h1 {
        padding-top: 46px;
        color: var(--color-orange);
        margin-bottom: -3px;

        @media (max-width: 1024px) {
            padding-top: 0;
        }
    }

    p {
        font-style: italic;
        margin-bottom: 50px;
    }

    .hero__image {
        img {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
        }

        @media (max-width: 425px) {
            img {
                position: absolute;
                top: 0;
                right: 0px;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: -1;
            }
        }
    }
}