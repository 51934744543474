.dialog {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    opacity: 0;
    transition: opacity var(--transition-duration);

    h3 {
        font-size: 20px !important;
    }

    .dialog__image {
        text-align: center;

        img {
            display: inline-block;
            width: 100%;
            max-width: 300px;
            object-fit: contain;
        }
    }

    .dialog__inner {
        z-index: 3;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 600px;
        width: 100%;
        padding: 30px;
        background-color: var(--color-white);
        border-radius: 20px;
        opacity: 0;
        transition: opacity var(--transition-duration);
    }

    .dialog__close {
        display: flex;
        flex-flow: column;
        align-items: flex-end;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    .dialog__content {
        margin-bottom: 16px;
    }

    .dialog__actions {
        display: flex;
        justify-content: space-between;
        gap: 10px;
    }

    .dialog__btn {
        width: 50%;
        text-align: center;
        line-height: 1;
    }
}

.dialog--active {
    display: block;
    opacity: 1;

    .dialog__inner {
        opacity: 1;
    }
}