.sidebar-item {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    align-items: center;
    gap: 20px;
    padding: 20px;
    border-bottom: 1px solid var(--color-black);
    transition: background-color var(--transition-duration);

    .item__image {
        a {
            background-color: var(--color-white);
            border-radius: 4px;
        }

        img {
            display: inline-block;
            max-width: 100px;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .item__content {
        font-weight: 700;
        color: var(--color-white);

        p {
            font-weight: 400;
        }

        a {
            color: var(--color-white);
            transition: color var(--transition-duration);
            margin-bottom: 10px;

            &:hover {
                color: var(--color-orange);
            }
        }
    }

    .item__actions {
        text-align: right;

        a {
            color: red;
            transition: color var(--transition-duration);

            svg {
                width: 25px;
                height: 25px;
            }

            &:hover {
                color: var(--color-white);
            }
        }
    }
}

.sidebar-item--dark {
    .item__content {
        color: var(--color-black);

        a {
            color: var(--color-black);
        }
    }
}