* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: var(--font-family);
    font-size: var(--font-size);
    font-weight: var(--font-weight);
    line-height: var(--font-height);
    color: var(--color-base);
}

ol,
ul {
    list-style: none;
}

img {
    display: block;
}

a {
    display: inline-block;
    text-decoration: none;
}

h1 {
    font-size: 96px !important;
    font-weight: 600;
    line-height: 1.37;

    @media (max-width: 1024px) {
        font-size: 48px !important;
    }
}

h2 {
    font-size: 48px !important;
    line-height: 1.23;

    @media (max-width: 1024px) {
        font-size: 36px !important;
    }
}

h3 {
    font-size: 48px !important;
    line-height: 1.23;
}

h4 {}

h5 {}

h6 {}