.shell {
    max-width: var(--shell-width);
    margin: 0 auto;
    padding-inline: 40px ;

    @media only screen and (max-width: 1024px) {
        padding-inline: 30px;
    }

    @media only screen and (max-width: 768px) {
        padding-inline: 20px;
    }
}

.shell--small {
    max-width: var(--shell-width-small);
}


.custom-radio input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create the custom radio button appearance */
  .custom-radio {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    margin-right: 15px;
  }

  .custom-radio .radio-mark {
    position: relative;
    width: 20px;
    height: 20px;
    background-color: #D9D9D9; /* Default background color (#D9D9D9) */
    border: 2px solid #ccc;
    border-radius: 50%; /* Make it circular */
    margin-right: 10px;
    display: inline-block;
  }

  /* Change background color when checked */
  .custom-radio input:checked + .radio-mark {
    background-color: #D9D9D9; /* Keep background color (#D9D9D9) */
    border-color: #D9D9D9; /* Border color to match the background */
  }

  /* Create the green inner dot */
  .custom-radio .radio-mark::after {
    content: "";
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background-color: #849074; /* Green dot color */
    border-radius: 50%;
  }

  /* Show the green dot when checked */
  .custom-radio input:checked + .radio-mark::after {
    display: block;
  }