.logo {
    @media only screen and (max-width: 1024px) {
        display: flex;
        flex-flow: column;
        align-items: center;
    }

    >img {
        max-width: 185px;
        width: 100%;
        object-fit: contain;
    }
}

.logo--large {
    >img {
        max-width: unset;
        width: 280px;
    }
}