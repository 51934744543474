.sidebar {
    opacity: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    right: 0;
    width: 30%;
    height: 100%;
    background-color: gray;
    transform: translateX(100%);
    transition: transform var(--transition-duration), opacity var(--transition-duration);
    z-index: 2;
    padding: 60px 30px;

    .sidebar__head,
    .sidebar__body {
        margin-bottom: 30px;
    }

    .sidebar__head {
        text-align: right;
    }

    .sidebar__head a {
        color: var(--color-white);
        transition: color var(--transition-duration);

        svg {
            width: 25px;
            height: 25px;
        }

        &:hover {
            color: var(--color-orange);
        }
    }
}

.sidebar.visible {
    opacity: 1;
    transform: translateX(0);
}