.products {
    padding: 60px 0;

    .products__container {
        display: grid;
        grid-template-columns: 1fr 3fr;
    }

    .products__col-1 {
        display: flex;
        flex-flow: column;
        gap: 20px;
    }

    .products__head {
        text-align: center;
        margin-bottom: 60px;
    }

    .products__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        margin-bottom: 60px;

        @media only screen and (max-width: 1400px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media only screen and (max-width: 1024px) {
            grid-template-columns: 1fr;
        }
    }

    .products__actions {
        text-align: center;
    }
}