:root {
    --shell-width: 1440px;
    --shell-width-small: 1400px;

    --transition-duration: .4s;

    --color-base: #111111;
    --color-background: #F0F0F0;
    --color-white: #ffffff;
    --color-black: #000000;
    --color-orange: #FFC633;

    --font-family: 'Montserrat', sans-serif;
    --font-size: 16px;
    --font-weight: 400;
    --font-height: 1;

    --mobile-width: 768px;
    --tablet-width: 1024px;
    --desktop-width: 1440px;
}