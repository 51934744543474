.cta {
    padding: 60px 0;

    .cta__head {
        text-align: center;
        margin-bottom: 40px;

        h3 {
            margin-bottom: 10px;
        }

        p {
            font-size: 20px;
        }
    }
}