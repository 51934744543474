.icon-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-white);
    color: var(--color-black);
    transition-property: background-color, color;
    transition-duration: var(--transition-duration);

    &:hover {
        background-color: var(--color-black);
        color: var(--color-white);
    }
}

.icon-circle--black {
    background-color: var(--color-black);
    color: var(--color-white);

    &:hover {
        background-color: var(--color-white);
        color: var(--color-black);
    }
}