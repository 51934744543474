.footer {
    padding: 0px 0;
    background-color: var(--color-background);

    .footer__inner {
        display: grid;
        grid-template-columns: 1fr 1fr 4fr;
        align-items: center;
        gap: 60px;
        padding: 40px 50px;
        border-bottom: 1px solid var(--color-black);
        /* margin-bottom: 40px; */

        @media only screen and (max-width: 1024px) {
            grid-template-columns: 1fr;
            text-align: center;
        }

        @media only screen and (max-width: 400px) {
            grid-template-columns: 1fr;
            text-align: center;
            padding: 40px 10px;
        }
    }

    .footer__content {
        >p {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 30px;
        }
    }

    .footer__contacts {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        gap: 15px;
        margin-bottom: 30px;

        @media only screen and (max-width: 1024px) {
            align-items: center;
        }

        li a {
            font-weight: 500;
            display: flex;
            align-items: center;
            gap: 10px;
            color: var(--color-blacks);

            &:hover {
                img {
                    rotate: -45deg;
                }
            }

            img {
                width: 20px;
                transition: all var(--transition-duration);
            }
        }
    }

    .footer__map {
        iframe {
            width: 100%;
            min-height: 300px;
        }
    }

    .footer__bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .footer__bottom-list {
        display: flex;
        gap: 15px;
        align-items: center;

        li {
            font-size: 12px;
            text-transform: uppercase;
        }

        li img {
            width: auto;
            height: auto;
        }
    }
}