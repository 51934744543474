.nav-trigger {
    display: none;
    width: 40px;
    height: 29px;
    position: relative;
    cursor: pointer;
    justify-self: flex-end;

    @media only screen and (max-width: 1024px) {
        display: block;
    }

    &.active {
        z-index: 2;
        animation: rotateHamburger .5s linear 0s forwards;

        span {
            background-color: var(--color-white);

            &:nth-child(1) {
                transform: translateY(12px) rotate(45deg);
            }

            &:nth-child(2) {
                width: 0;
            }

            &:nth-child(3) {
                transform: translateY(-13px) rotate(-45deg);
            }
        }
    }

    span {
        display: block;
        width: 100%;
        height: 4px;
        border-radius: 99em;
        background-color: var(--color-black);
        position: absolute;
        left: 0;
        transition: all .3s;
        &:nth-child(1) {
            top: 0;
        }

        &:nth-child(2) {
            top: 50%;
            transform: translateY(-50%);
        }

        &:nth-child(3) {
            bottom: 0;
        }

    }

    @media only screen and (max-width: 1024px) {
        display: block;
    }
}