.tab {
    background-color: var(--color-black);
    padding: 16px 0;

    .tab__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        color: var(--color-white);
    }

    .tab__inner p a {
        padding-inline: 5px 10px;
        color: var(--color-orange);
        transition: color var(--transition-duration);

        &:hover {
            color: var(--color-white);
        }
    }
}