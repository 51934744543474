.logo-slider {
    padding: 16px 34px;
    background-color: var(--color-black);
    overflow: hidden;
    white-space: nowrap;

    .logo-slider__inner {
        display: inline-block;
        animation: 15s slide infinite linear;
    }

    img {
        display: inline;
        height: 54px;
        width: auto;
        margin: 0 40px !important;
    }
}

@keyframes slide {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(-100%);
    }
}

.logo-slider--mobile {
    @media only screen and (max-width: 1024px) {
        display: none !important;
    }
}