.form-cta {
    >.form__input {
        margin-bottom: 20px;
    }

    .form__row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        margin-bottom: 20px;

        @media only screen and (max-width: 1024px) {
            grid-template-columns: 1fr;
        }
    }

    .form__input {
        display: flex;
        flex-flow: column;
        align-items: center;

        textarea,
        input {
            border: none;
            border-radius: 20px;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 40px;
            padding-right: 40px;
            background-color: white;
            transition: box-shadow var(--transition-duration);

            &::placeholder {
                font-size: 16px;
                font-family: var(--font-family);
                transition: opacity var(--transition-duration);
            }

            &:focus {
                outline: none;
                box-shadow: 0 0 5px 1px rgba(0, 0, 255, .3);

                &::placeholder {
                    opacity: 0;
                }
            }
        }

        textarea {
            resize: none;
            min-height: 150px;
        }
    }

    .form__actions {
        text-align: center;
        margin-top: 40px;
    }
}