.search {
    position: relative;

    &:before,
    &:after {
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: opacity var(--transition-duration);
        z-index: 1;
    }

    &:before {
        content: '';
        left: 16px;
        width: 21px;
        height: 21px;
        background: url(../../images/search.svg) no-repeat center;
        background-size: contain;
    }

    &:after {
        content: 'Потърси';
        font-weight: 600;
        color: rgba(0, 0, 0, .4);
        left: 46px;
    }

    &:has(input:focus) {

        &:before,
        &:after {
            opacity: 0;
        }
    }

    input {
        position: relative;
        padding: 13px 16px;
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        background-color: unset;
        border: unset;
        border-radius: 99em;
        background-color: var(--color-background);

        &:focus {
            outline: none;
            box-shadow: 0 0 5px 1px rgba(0, 0, 255, .3);
        }
    }

    @media only screen and (max-width: 1024px) {
        display: none;
    }
}