.product {
    a {
        cursor: pointer;
        width: 100%;
        height: 100%;
    }

    .product__image {
        text-align: center;
        margin-bottom: 20px;
        border-radius: 20px;
        background-color: var(--color-background);
        overflow: hidden;
        height: 350px;

        &:hover {
            img {
                scale: 1.2;
            }
        }

        img {
            display: inline-block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: scale var(--transition-duration);
        }
    }

    .product__content {
        font-weight: 700;

        a {
            display: block;
            font-size: 20px;
            margin-bottom: 10px;
            color: var(--color-black);
            transition: color var(--transition-duration);

            &:hover {
                color: var(--color-orange);
            }
        }
    }

    .product__price {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 20px;
        margin-top: 10px;
    }

    .product__price-old {
        color: gray;
        text-decoration: line-through;
    }

    .product__price-discount {
        color: red;
        font-size: 14px;
        background-color: rgba(255, 0, 0, .2);
        padding: 5px 10px;
        border-radius: 99em;
    }
}